import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLink } from "@egds/react-core/link";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { Destination, DestinationListProps } from "components/shared/DestinationCardView/typings";

const ListsView = (props: DestinationListProps) => {
  const { baseKey, destinations, localiseKey, moduleName } = props;
  const keyHelper = new ItemKeyHelper(baseKey);
  const { formatText } = useLocalization();

  return (
    <UitkLayoutGrid columns={{ medium: 2, large: 4 }} space="four">
      {destinations.map((destination: Destination) => {
        const name = destination.localisedName || destination.name;

        return (
          <UitkLink inline key={keyHelper.next()}>
            <TrackedLink
              aria-label={formatText(localiseKey, name)}
              data-testid="destinationCardLink"
              moduleName={moduleName}
              rfrr="link-destination"
              href={destination.url}
            >
              {name}
            </TrackedLink>
          </UitkLink>
        );
      })}
    </UitkLayoutGrid>
  );
};

export default ListsView;
