import * as React from "react";
import { GridContainerColumnsContext, widthVariableViewport } from "./GridContainer";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { Viewport, ViewLarge, ViewSizes, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { GridItemWrapperProps, ColumnsForGrid } from "./typings";

export const GridItemWrapper: React.FC<GridItemWrapperProps> = (props) => {
  const { templateComponent, children } = props;
  const { config } = templateComponent;

  const gridContainerColumns = React.useContext(GridContainerColumnsContext);
  const gridItemContent = (viewSize: ViewSizes) => {
    // @ts-ignore
    const viewPortWidth = config[widthVariableViewport[viewSize]];
    return (
      <>
        {!gridContainerColumns ? (
          children
        ) : (
          <UitkLayoutGridItem colSpan={calculateColSpan(gridContainerColumns, viewPortWidth)}>
            <div>{children}</div>
          </UitkLayoutGridItem>
        )}
      </>
    );
  };
  return (
    <Viewport>
      <ViewLarge>{gridItemContent(ViewSizes.LARGE)}</ViewLarge>
      <ViewMedium>{gridItemContent(ViewSizes.MEDIUM)}</ViewMedium>
      <ViewSmall>{gridItemContent(ViewSizes.SMALL)}</ViewSmall>
    </Viewport>
  );
};

const calculateColSpan = (gridColumns: ColumnsForGrid, widthPercentage: string) => {
  const ColSpanForWidthMapping: any = {
    1: {
      "100": 1,
    },
    2: {
      "50": 1,
    },
    3: {
      "33": 1,
      "66": 2,
    },
    4: {
      "25": 1,
      "50": 2,
      "75": 3,
    },
  };

  return ColSpanForWidthMapping[gridColumns][widthPercentage] || gridColumns;
};
