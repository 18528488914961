import * as React from "react";
import { inject, observer } from "mobx-react";
import { AdvertisingStore } from "bernie-plugin-ads-loader-script";
import { UitkCardPlaceholder } from "@egds/react-core/cards";
import { Logger } from "bernie-logger";
import { logger } from "bernie-core";
import { MESO_AD_LOAD_FAIL } from "src/config/systemEvents";

enum view {
  ad,
  loader,
  fallback,
}

const mesoAdEventName = "mesoAd";
const mesoFallbackEventName = "mesoFallback";

interface NativeAdProps {
  uci: string;
  type: string;
  children?: JSX.Element[] | JSX.Element;
  advertising?: AdvertisingStore;
  className?: string;
  logger?: Logger;
  showFallback?: boolean;
}

const jsonToSplunkFriendlyString = (json: { [x: string]: any }) =>
  Object.keys(json)
    .map((key) => `${key}=${json[key]}`)
    .join(" ");

export const NativeAd: React.SFC<NativeAdProps> = logger("NativeAd")(
  inject("advertising")(
    observer((props: NativeAdProps) => {
      const { uci, type, children } = props;
      const mesoNative: React.RefObject<JSX.IntrinsicElements["meso-native"]> = React.createRef();
      const [initialView, setCurrentView] = React.useState(view.loader);
      let currentView = initialView;

      if (props.showFallback || props.advertising?.adBlockerEnabled === true || props.advertising?.canTrack === false) {
        currentView = view.fallback;
      }

      /* istanbul ignore next */
      props.advertising?.includeAd?.();

      const removeWindowdLoadEventHandler = () => {
        if (window) {
          window.removeEventListener(mesoFallbackEventName, handleWindowAdLoadEvent);
        }
      };

      const removeAdLoadEventHandler = () => {
        if (mesoNative && mesoNative.current) {
          mesoNative.current.removeEventListener(mesoAdEventName, handleAdLoadEvent);
        }
      };

      const handleWindowAdLoadEvent = (event: CustomEvent) => {
        if (event && event.detail && event.detail.code === "FETCH-ADS-FAIL") {
          if (props.logger && props.advertising && props.advertising.adBlockerEnabled !== true) {
            props.logger.logEvent(
              MESO_AD_LOAD_FAIL,
              "failed to fetch MeSo ad",
              jsonToSplunkFriendlyString(event.detail)
            );
          }
          setCurrentView(view.fallback);
          removeWindowdLoadEventHandler();
        }
      };

      const handleAdLoadEvent = (event: CustomEvent) => {
        if (
          props.logger &&
          event.detail &&
          event.detail.code !== "AD-LOAD-PASS" &&
          props.advertising &&
          props.advertising.adBlockerEnabled !== true
        ) {
          props.logger.logEvent(MESO_AD_LOAD_FAIL, "failed to fetch MeSo ad", jsonToSplunkFriendlyString(event.detail));
        }
        setCurrentView(event.detail && event.detail.code === "AD-LOAD-PASS" ? view.ad : view.fallback);
        removeAdLoadEventHandler();
      };

      React.useEffect(() => {
        if (mesoNative && mesoNative.current) {
          mesoNative.current.addEventListener(mesoAdEventName, handleAdLoadEvent);
        }
        if (window) {
          window.addEventListener(mesoFallbackEventName, handleWindowAdLoadEvent);
        }

        return removeAdLoadEventHandler;
      }, [mesoNative.current]);

      return (
        <>
          <div className={currentView === view.fallback ? "" : props.className}>
            <meso-native
              class={currentView === view.ad ? "" : "is-visually-hidden"}
              aria-hidden={currentView === view.ad ? "false" : "true"}
              uci={uci}
              nativetype={type}
              isfluid
              adslot={uci}
              data-testid="meso-native"
              ref={mesoNative}
            />
            <div
              className={currentView === view.fallback ? "fallback" : "is-visually-hidden"}
              aria-hidden={currentView === view.fallback ? "false" : "true"}
              data-testid="native-ad-container"
            >
              {children}
            </div>
            {currentView === view.loader && (
              <UitkCardPlaceholder className="uitk-card uitk-card-roundcorner-all uitk-card-empty" />
            )}
          </div>
        </>
      );
    })
  )
);
