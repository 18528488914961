import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { GlobalConfig } from "stores/wizard/config";
import { useLocalization } from "@shared-ui/localization-context";

export interface CobrandImageProps {
  globalConfig?: GlobalConfig;
}

export const CobrandImage: React.FunctionComponent<CobrandImageProps> = ({ globalConfig }) => {
  const { formatText } = useLocalization();
  const altText = formatText("cobrandImage.altText");
  const { cobrandImageUrl = "" } = globalConfig || {};

  if (!cobrandImageUrl) {
    return null;
  }

  return (
    <UitkSpacing padding="two">
      <UitkLayoutGrid>
        <UitkLayoutGridItem>
          <UitkFigure imageFit="contain" style={{ alignSelf: "center", paddingBottom: "15%" }}>
            <UitkImage alt={altText} src={cobrandImageUrl} />
          </UitkFigure>
        </UitkLayoutGridItem>
      </UitkLayoutGrid>
    </UitkSpacing>
  );
};
