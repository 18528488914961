import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";

const FeaturedReview = (props: { hotel: Hotel; className: string }) => {
  const { hotel, className } = props;
  const { featuredReviews } = hotel;
  const featuredReview = featuredReviews?.[0];

  if (!featuredReview) {
    return null;
  }

  const { formatText } = useLocalization();

  return (
    <UitkLayoutFlex direction="column" className={className}>
      <UitkSpacing margin={{ blockend: "three" }}>
        <UitkText overflow="truncate-3-lines" size={300}>
          {`\u0022${featuredReview.review}\u0022`}
        </UitkText>
      </UitkSpacing>
      <UitkText size={200}>{formatText("hotels.review.reviewedOn", featuredReview.reviewDate)}</UitkText>
    </UitkLayoutFlex>
  );
};

export default FeaturedReview;
