/**
 * Serve as an offset grid item to align items inside a @egds/react-core/layout-grid
 * without losing the ability for the other items to keep their width based
 * on the colSpan definition (for fluid design based on viewport)
 *
 * @egds/react-core/layout-grid uses css grid under the hood, so, by design it's not possible to keep this
 *
 * This pattern is inspired on grid system of css frameworks like Foundation, Bootstrap, Tailwind, etc
 *
 * @example
 * <UitkLayoutGrid columns={mainGridColumns}>
 *    <OffsetGridItem colspan={offsetColSpan} />
 *    <Button colSpan={buttonColSpan} />
 * </UitkLayoutGrid>
 */

import * as React from "react";
import { UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";

export interface OffsetGridItemProps {
  colSpan: UitkLayoutConditionalGridSpan;
}

export const OffsetGridItem: React.FunctionComponent<OffsetGridItemProps> = ({ colSpan }) => (
  <UitkLayoutGridItem colSpan={colSpan}>
    <div />
  </UitkLayoutGridItem>
);
