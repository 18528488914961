import * as React from "react";

import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigureAspectRatioType, UitkImage, UitkFigure } from "@egds/react-core/images";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem, UitkLayoutFlexProps } from "@egds/react-core/layout-flex";
import { UitkScrim, UitkScrimType, UitkScrimTypes } from "@egds/react-core/scrim";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";

export type ScrimCardProps = {
  altText?: string;
  id: string;
  dataTestId?: string;
  className: string;
  titleSlot?: JSX.Element | JSX.Element[];
  aspectRatio: UitkFigureAspectRatioType;
  backgroundImageURL: string;
  subtitleSlot?: JSX.Element | JSX.Element[];
  footerSlot?: JSX.Element | JSX.Element[];
  scrimType?: UitkScrimTypes;
  spacing?: UitkSpacingProps;
  interiorSpacing?: UitkSpacingProps;
  domRef?: React.RefObject<HTMLElement> | null;
  justifyContent?: UitkLayoutFlexProps["justifyContent"];
  mainGridColumns?: UitkLayoutConditionalGridTrack;
  headingCols?: UitkLayoutConditionalGridSpan;
  padding?: string;
  border?: boolean;
  verticallyCenter?: boolean;
  lazyLoading?: "lazy" | "eager" | "off";
};

export const ScrimCard: React.FC<ScrimCardProps> = ({
  id,
  dataTestId = "scrim-card",
  className,
  titleSlot,
  aspectRatio,
  backgroundImageURL,
  altText,
  subtitleSlot = null,
  footerSlot = null,
  scrimType = UitkScrimType.OVERLAY,
  spacing = {},
  interiorSpacing = { padding: { inline: "six" } },
  domRef = null,
  justifyContent = "space-between",
  mainGridColumns = { small: 1, medium: 2 },
  headingCols = { small: 1, medium: 2 },
  padding,
  border = true,
  verticallyCenter = false,
  lazyLoading = "off",
}) => {
  const subheadingCols: UitkLayoutConditionalGridSpan = { small: 1, medium: 1 };
  const hasNoPadding = padding === SpacingSize.NONE;
  const blockendSpacing = hasNoPadding ? "unset" : "six";
  const layoutPosition = verticallyCenter ? "center" : "zero";
  const layoutType = verticallyCenter ? "absolute" : "relative";

  return (
    <UitkSpacing padding={{ blockend: blockendSpacing }}>
      <section data-testid={dataTestId} id={id} ref={domRef}>
        <UitkSpacing {...spacing}>
          <UitkCard className={className} border={border}>
            <UitkCardContentSection padded={false}>
              <UitkFigure ratio={aspectRatio}>
                <UitkImage alt={altText} src={backgroundImageURL} lazyLoading={lazyLoading} />
                <UitkScrim type={scrimType}>
                  <UitkSpacing {...interiorSpacing}>
                    <UitkLayoutPosition type={layoutType} position={{ top: layoutPosition }} cloneElement>
                      <UitkLayoutFlex direction="column" justifyContent={justifyContent} style={{ height: "inherit" }}>
                        <UitkLayoutFlexItem>
                          <div>
                            {titleSlot && (
                              <UitkLayoutGrid columns={mainGridColumns}>
                                <UitkLayoutGridItem colSpan={headingCols}>{titleSlot}</UitkLayoutGridItem>
                              </UitkLayoutGrid>
                            )}

                            {subtitleSlot && (
                              <UitkSpacing padding={{ blockstart: "three" }}>
                                <UitkLayoutGrid columns={mainGridColumns}>
                                  <UitkLayoutGridItem colSpan={subheadingCols}>{subtitleSlot}</UitkLayoutGridItem>
                                </UitkLayoutGrid>
                              </UitkSpacing>
                            )}
                          </div>
                        </UitkLayoutFlexItem>

                        {footerSlot && (
                          <UitkLayoutFlexItem>
                            <div>{footerSlot}</div>
                          </UitkLayoutFlexItem>
                        )}
                      </UitkLayoutFlex>
                    </UitkLayoutPosition>
                  </UitkSpacing>
                </UitkScrim>
              </UitkFigure>
            </UitkCardContentSection>
          </UitkCard>
        </UitkSpacing>
      </section>
    </UitkSpacing>
  );
};
