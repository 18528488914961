import * as React from "react";

import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

import { RelevantContent } from "@shared-ui/retail-product-relevant-content";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";
import { UitkSpacing } from "@egds/react-core/spacing";
const WrappedRelevantContent = ProductBexApiWrapper(RelevantContent);

export const RelevantContentModule = ({ context }: { context: ExtendedContextStore }) => (
  <Experiment name="Filter_ranking_model_for_amenity_module_Web">
    <ExperimentControl />
    <ExperimentVariant bucket={1}>
      <UitkSpacing padding={{ blockend: "six" }}>
        <div>
          <WrappedRelevantContent context={context} />
        </div>
      </UitkSpacing>
    </ExperimentVariant>
    <ExperimentVariant bucket={2}>
      <UitkSpacing padding={{ blockend: "six" }}>
        <div>
          <WrappedRelevantContent context={context} skipSsr />
        </div>
      </UitkSpacing>
    </ExperimentVariant>
  </Experiment>
);
