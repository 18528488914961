import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import {
  FrequentlyAskedQuestions as SharedUIFAQ,
  faqView,
} from "@shared-ui/retail-travel-content-frequently-asked-questions";
import { inject, observer } from "mobx-react";
import { FaqProps } from "./typings";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

const FrequentlyAskedQuestions = (props: FaqProps) => {
  const { context, templateComponent, uriContext: uriContextStore } = props;
  const uriContext = uriContextStore?.get();

  if (!context || !uriContext) {
    return null;
  }

  const { displayThreshold, limit, contentTopic, isExpando, view, showBorder } = templateComponent.config;

  const inputs = {
    uriContextV1: uriContext as React.ComponentProps<typeof SharedUIFAQ>["inputs"]["uriContextV1"],
    limit: limit || 12,
    contentTopic: contentTopic,
  };

  return (
    <UitkSpacing
      margin={
        showBorder
          ? {
              small: { blockend: "two", inline: "three" },
              medium: { blockend: "two", inline: "unset" },
            }
          : { block: "three" }
      }
    >
      <div id="FAQ">
        <LazyLoad context={context}>
          <SharedUIFAQ
            inputs={inputs}
            displayThreshold={displayThreshold}
            isExpando={isExpando}
            view={view as faqView}
            showBorder={showBorder}
          />
        </LazyLoad>
      </div>
    </UitkSpacing>
  );
};

export const FrequentlyAskedQuestionsModule = inject("uriContext")(observer(FrequentlyAskedQuestions));

export default FrequentlyAskedQuestionsModule;
