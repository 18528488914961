import { TRAVELERS_HIDDEN_INPUT_TYPE } from "components/shared/TravelersField";
import { PackageWizardState } from "stores/wizard/state";
import { useConfigOverrides } from "stores/wizard/config/overrideConfigByView/useConfigOverrides";
import { UitkLayoutConditionalGridSpan, UitkLayoutConditionalGridStartEnd } from "@egds/react-core/layout-grid";

export const useHotwirePackageOverrides = (packageWizardState: PackageWizardState) =>
  useConfigOverrides(packageWizardState, () => {
    const { date: dateConfig, travelers, form, lobLabels, partialStayDate, subLOB } = packageWizardState.config;
    dateConfig.defaultOffsetDates = {
      start: 1,
      end: 2,
    };
    dateConfig.maxDaysRange = 26;
    partialStayDate.maxDaysRange = 26;

    form.submitButton.locToken = "hotwire.wizard.package.submit";

    form.action = "https://www.hotwire.com/vacation/results.jsp";
    lobLabels.carsLabel = "hotwire.wizard.package.lobLabels.carsLabel";
    lobLabels.flightLabel = "hotwire.wizard.package.lobLabels.flightLabel";
    lobLabels.hotelsLabel = "hotwire.wizard.package.lobLabels.hotelsLabel";

    travelers.hiddenInputType = TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_PACKAGE_SEARCH;
    subLOB.flightCar.travelers.hiddenInputType = TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_PACKAGE_SEARCH;
    subLOB.hotelCar.travelers.hiddenInputType = TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_PACKAGE_SEARCH;
    travelers.maxRooms = 3;
    travelers.minChildAge = 2;
    subLOB.hotelCar.travelers.maxRooms = 3;

    packageWizardState.config.pillsLabelKey = "hotwire.wizard.package.pillsLabelKey";
    packageWizardState.config.minimumLOBsNeeded = 2;
  });

export interface HotwireFlightHotelColSpansParams {
  isMultiLOB: boolean;
  partialStay: boolean;
}

/**
 * Common UITK Grid Column Spans for Flight+Hotel and Flight+Hotel+Car
 */
export const getHotwireFlightHotelColSpans = ({ isMultiLOB, partialStay }: HotwireFlightHotelColSpansParams) => {
  const locationColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12, large: isMultiLOB ? 12 : 8 };
  const datesColStart: UitkLayoutConditionalGridStartEnd = { small: 1, medium: 1, large: 1 };
  const datesColEnd: UitkLayoutConditionalGridStartEnd = { small: "end", medium: 7, large: isMultiLOB ? 7 : 5 };
  const datesPartialStayColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 6, large: isMultiLOB ? 6 : 4 };
  const submitColSpan: UitkLayoutConditionalGridSpan = {
    small: 2,
    medium: 6,
    large: isMultiLOB && partialStay ? 6 : 4,
  };
  const multiLobNonPartialOffsetColSpan: UitkLayoutConditionalGridSpan = { large: 4 };

  return {
    locationColSpan,
    datesColStart,
    datesColEnd,
    datesPartialStayColSpan,
    submitColSpan,
    multiLobNonPartialOffsetColSpan,
  };
};
