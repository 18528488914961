import * as React from "react";
import { UitkCarousel } from "@egds/react-core/carousel";
import { IsomorphicCarouselProps } from "./typings";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import {
  UitkLayoutGrid,
  UitkLayoutGridProps,
  UitkLayoutGridItem,
  UitkLayoutGridColumnRow,
} from "@egds/react-core/layout-grid";

/** The max amount of columns to use in the grid in case there are not enough elements for carousel. Three looks best. */
const MAX_ITEMS_GRID = 3;

/**
 * This component should be rendered when there are not enough elements to display in a UitkCarousel as it will
 * break the layout based on the amount of elements.
 * A client of the IsomorphicCarousel could select any number to present at the different viewports, as a default, if this
 * value is larger than MAX_ITEMS_GRID, this component defaults to MAX_ITEMS_GRID and places the elements in a fixed grid instead of using
 * the UitkCarousel.
 * see: https://jira.expedia.biz/browse/FLEX-4493 or https://jira.expedia.biz/browse/FLEX-4518
 * @param props
 */
const NotEnoughElementsCarouselJkActualyAGrid = ({
  children,
  columns,
  className,
}: IsomorphicCarouselProps & Required<Pick<UitkLayoutGridProps, "columns">>) => {
  const gridColumns = columns || MAX_ITEMS_GRID;
  const keyHelper = new ItemKeyHelper("GridCarousel");

  return (
    <UitkLayoutGrid columns={gridColumns} space="two" data-testid="grid-carousel-container" className={className}>
      <>
        {children.map((elem) => (
          <UitkLayoutGridItem key={keyHelper.next()}>{elem}</UitkLayoutGridItem>
        ))}
      </>
    </UitkLayoutGrid>
  );
};

/**
 * Wrapper component that determines whether there are enough elements that renders a GridLayout when there
 * are not enough elements to render a Carousel based on the configured client.
 * @param props UitkCarousel default props and minItems as configured by the client
 */
const BlossomCarousel = (props: IsomorphicCarouselProps & { minItems: number }) => {
  const { minItems, children } = props;
  const hasFewerOrSameItemsThanMaxGrid = children.length <= MAX_ITEMS_GRID;
  const hasFewerItemsThanVisibleConfigured = minItems && children.length < minItems;

  // Only render the grid if there are less than three elements and that's less than the configured visible items
  if (hasFewerItemsThanVisibleConfigured && hasFewerOrSameItemsThanMaxGrid) {
    const minColumns = minItems > MAX_ITEMS_GRID ? MAX_ITEMS_GRID : minItems;

    return <NotEnoughElementsCarouselJkActualyAGrid columns={minColumns as UitkLayoutGridColumnRow} {...props} />;
  }

  return <UitkCarousel {...props} />;
};

/**
 * Normalizes itemsVisble prop to protect against bugs in uitk-react-carousel when itemsVisble disagrees with the actual number of chilren
 * Takes same props as uitk-react-carousel.
 * @param {IsomorphicCarouselProps} props
 */
export const IsomorphicCarousel = (props: IsomorphicCarouselProps) => {
  const {
    itemsVisible: { lg, md, sm },
  } = props;

  return (
    <Viewport>
      <ViewSmall>
        <BlossomCarousel minItems={sm} {...props} />
      </ViewSmall>
      <ViewMedium>
        <BlossomCarousel minItems={md} {...props} />
      </ViewMedium>
      <ViewLarge>
        <BlossomCarousel minItems={lg} {...props} />
      </ViewLarge>
    </Viewport>
  );
};

export default IsomorphicCarousel;
