import * as React from "react";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";

const AmenitiesSection = (props: { hotel: Hotel; className: string }) => {
  const { hotel, className } = props;
  const { hotelAmenityCategories } = hotel;

  if (!hotelAmenityCategories?.length) {
    return null;
  }

  return (
    <div className={className}>
      <UitkLayoutFlex space="twelve">
        {hotelAmenityCategories.slice(0, 2).map((amenity) => (
          <UitkLayoutFlex key={amenity.code}>
            <UitkIcon size={UitkIconSize.SMALL} name={amenity.code.toLowerCase()} />
            <UitkSpacing margin={{ inlinestart: "one" }}>
              <UitkText inline size={300}>
                {amenity.text}
              </UitkText>
            </UitkSpacing>
          </UitkLayoutFlex>
        ))}
      </UitkLayoutFlex>
    </div>
  );
};

export default AmenitiesSection;
