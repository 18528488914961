import * as React from "react";
import { observer } from "mobx-react";

import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkLink } from "@egds/react-core/link";
import {
  UitkMenu,
  UitkMenuTrigger,
  UitkMenuContainer,
  UitkMenuList,
  PositionType,
  MenuItemConfig,
} from "@egds/react-core/menu";

import { useLocalization } from "@shared-ui/localization-context";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { locKeys } from "./l10n";

export interface PreferredAirline {
  label: string;
  value: string;
}

export interface PreferredAirlineInputProps {
  onSelectPreferredAirline: (flightAirline: PreferredAirline) => void;
  wizardState: ClientWizardStateStore;
}

export const PreferredAirlineInput: React.FC<PreferredAirlineInputProps> = observer(
  ({ wizardState, onSelectPreferredAirline }) => {
    const { formatText } = useLocalization();
    const linkRef: React.RefObject<HTMLAnchorElement> = React.createRef();

    const { airlineOptions = "" } = wizardState.flightWizardState.config;

    let preferredAirlineOptions: PreferredAirline[] = [];
    try {
      preferredAirlineOptions = JSON.parse(`[${airlineOptions}]`);
    } catch (ex) {
      preferredAirlineOptions = [];
    }

    if (preferredAirlineOptions.length === 0) {
      return null;
    }

    const preferredAirlineLabel = formatText(locKeys.flightPreferredAirlineLabel) || "Preferred Airline";
    const { flightAirline } = wizardState.globalWizardState;

    if (!flightAirline) {
      onSelectPreferredAirline(preferredAirlineOptions[0]);
    }

    const currentPreferredAirlineLabel = flightAirline ? flightAirline.label : preferredAirlineOptions[0].label;

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const handleMenuTriggerClick = (event: React.MouseEvent) => {
      event.preventDefault();
      setIsMenuOpen(!isMenuOpen);
    };

    const handlePreferredAirlineClick = (preferredAirline: PreferredAirline) => () => {
      onSelectPreferredAirline(preferredAirline);
      setIsMenuOpen(false);
      linkRef.current?.focus();
    };

    return (
      <UitkLayoutGridItem>
        <div>
          <UitkMenu id="preferred-airline-input" isOpen={isMenuOpen} onTriggerClick={handleMenuTriggerClick}>
            <UitkMenuTrigger>
              <UitkLink id="preferred-airline-input-trigger" data-testid="preferred-airline-input-trigger">
                <a
                  href=""
                  role="button"
                  aria-label={`${preferredAirlineLabel} ${currentPreferredAirlineLabel}`}
                  ref={linkRef}
                >
                  {currentPreferredAirlineLabel}
                  <UitkIcon name="expand_more" />
                </a>
              </UitkLink>
            </UitkMenuTrigger>

            <UitkMenuContainer position={PositionType.LEFT}>
              <UitkMenuList
                items={preferredAirlineOptions.map(
                  (preferredAirline): MenuItemConfig => ({
                    attributes: {
                      role: "menuItem",
                      tabindex: 0,
                    },
                    className: "uitk-list-item",
                    label: preferredAirline.label,
                    href: "#",
                    typeAnchor: true,
                    onClick: handlePreferredAirlineClick(preferredAirline),
                  })
                )}
              />
            </UitkMenuContainer>
          </UitkMenu>
        </div>
      </UitkLayoutGridItem>
    );
  }
);
