import { UitkRatingIcon } from "@egds/react-core/rating";

enum localeType {
  EN_AU = "en_AU",
}

enum siteIds {
  EXPEDIA_CN = 75,
  AIRASIAGO_CN = 2000,
  CORPORATE_EXPCUST_CN = 60086,
  EXPEDIA_US = 100001,
  HCOM_CN = 300000042,
}

export const ratingIcon = (locale: string, siteId: number = 1): UitkRatingIcon => {
  return locale === localeType.EN_AU || siteId in siteIds ? "alternate" : "star";
};
