import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";

interface ValuePropositionProps {
  hotel: Hotel;
  className?: string;
}

const ValueProposition = React.memo(({ hotel, className = "" }: ValuePropositionProps) => {
  const { freeCancellation, payLater } = hotel;

  if (!(freeCancellation || payLater)) {
    return null;
  }

  const { formatText } = useLocalization();

  return (
    <UitkLayoutFlex direction="column" className={`ValueProposition ${className}`}>
      {freeCancellation && <UitkText size={300}>{formatText("hotels.freeCancellation")}</UitkText>}
      {payLater && <UitkText size={300}>{formatText("hotels.reserveNowPayLater")}</UitkText>}
    </UitkLayoutFlex>
  );
});

export default ValueProposition;
