import { observer } from "mobx-react";
import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkCard } from "@egds/react-core/cards";

export interface TooltipProps {
  width: number;
  left: number;
  top: number;
  content: any;
}

export const Tooltip = observer((props: TooltipProps) => {
  const [top, setTop] = React.useState<string>("");
  const [left, setLeft] = React.useState<string>("");
  const [visibility, setVisibility] = React.useState<"hidden" | "visible">("hidden");
  const tooltipBox = React.useRef<HTMLDivElement | null>(null);
  const tooltipTopAdjustment = 1.5;
  const tooltipLeftAdjustment = 0.5;

  React.useEffect(() => {
    const tooltipRect = tooltipBox.current?.getBoundingClientRect();
    if (tooltipRect) {
      const resolvedTop = props.top - tooltipRect.height * tooltipTopAdjustment;
      const resolvedX = props.left - tooltipRect.width * tooltipLeftAdjustment;
      setTop(`${resolvedTop}px`);
      setLeft(`${resolvedX}px`);
    }
  }, [props.top, props.left]);

  React.useEffect(() => {
    setVisibility("visible");
  }, [top, left]);

  return (
    <UitkCard>
      <UitkLayoutPosition
        data-testid="tooltip-container"
        className="tooltip elevation"
        style={{ left, top, visibility }}
      >
        <UitkSpacing padding={{ inline: "three", block: "three" }}>
          <div className="toolTipContent" ref={tooltipBox}>
            {props.content}
          </div>
        </UitkSpacing>
      </UitkLayoutPosition>
    </UitkCard>
  );
});
