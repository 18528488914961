import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkText, UitkTextProps } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { getRatingText } from "components/utility/Reviews";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";

const GuestRating = (props: { hotel: Hotel; alignment?: UitkTextProps["align"] }) => {
  const { formatText } = useLocalization();
  const { hotel, alignment } = props;
  const { hotelOverallReviewRating, reviewCount } = hotel;

  const numberOfReviewsLocalized = "hotels.review.reviewCount";

  if (!(hotelOverallReviewRating && reviewCount)) {
    return (
      <UitkText size={300} align={alignment}>
        {formatText(numberOfReviewsLocalized, 0, "0")}
      </UitkText>
    );
  }

  return (
    <div>
      <UitkText inline size={300} align={alignment} theme="emphasis" weight="medium">
        {formatText("hotelRecentReviews.ratingSlashNoSpace", Math.round(hotelOverallReviewRating))}
      </UitkText>
      <UitkSpacing margin={{ inlinestart: "one" }}>
        <UitkText size={300} inline align={alignment}>
          {getRatingText(hotelOverallReviewRating)} ({formatText(numberOfReviewsLocalized, reviewCount, reviewCount)})
        </UitkText>
      </UitkSpacing>
    </div>
  );
};

export default GuestRating;
