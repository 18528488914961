import * as React from "react";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { LobTitleProps } from "./typings";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { observer } from "mobx-react";

export const LobTitle: React.FunctionComponent<LobTitleProps> = observer(
  ({ lobState, customTitle, mobileHeadingSize }) => {
    const { formatText } = useLocalization();
    const title = customTitle || formatText(lobState.config.heading.locTitleToken);

    if (lobState.config.heading.hideLobTitle) {
      return null;
    }

    return (
      <Viewport>
        <ViewSmall>
          <UitkHeading tag="h1" size={mobileHeadingSize ?? 3}>
            {title}
          </UitkHeading>
        </ViewSmall>
        <ViewMedium>
          {lobState.config.heading.differentiatedTitles ? (
            <UitkText weight="medium" theme="emphasis" size={700}>
              {formatText(lobState.config.heading.locTitleDesktopTextToken)}
            </UitkText>
          ) : (
            <UitkHeading tag="h1" size={3}>
              {title}
            </UitkHeading>
          )}
        </ViewMedium>
      </Viewport>
    );
  }
);
