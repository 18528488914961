import * as React from "react";

import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { AttributionText } from "components/shared/AttributionText/AttributionText";
import { useLocalization } from "@shared-ui/localization-context";
import { DestinationCardProps } from "components/shared/DestinationCardView/typings";
import { UitkSpacing } from "@egds/react-core/spacing";

const DestinationCard = (props: DestinationCardProps) => {
  const { name, description, url, dxImage, localiseKey, moduleName } = props;
  const { formatText } = useLocalization();
  const isDescriptionPresent = Boolean(description?.length);

  return (
    <UitkCard border>
      <UitkFigure ratio={UitkFigure.AspectRatio.R16_9} className="uitk-card-roundcorner-all">
        <UitkImage src={dxImage.mediaUrl} alt={dxImage.alt} placeholderImage lazyLoading="lazy" />
        <AttributionText
          source={dxImage.source}
          origin={dxImage.origin}
          attribUrl={dxImage.attribUrl}
          authorName={dxImage.authorName}
          authorUrl={dxImage.authorUrl}
          licenseSuite={dxImage.licenseSuite}
          licenseName={dxImage.licenseName}
          licenseUrl={dxImage.licenseUrl}
        />
      </UitkFigure>

      <UitkCardContentSection>
        <UitkHeading tag="h3" size={6}>
          {name}
        </UitkHeading>

        {isDescriptionPresent && (
          <UitkSpacing margin={{ blockstart: "two" }}>
            <UitkText size={300}>{description?.join("")}</UitkText>
          </UitkSpacing>
        )}
      </UitkCardContentSection>

      <UitkCardLink>
        <TrackedLink
          aria-label={formatText(localiseKey, name)}
          data-testid="destinationCardLink"
          moduleName={moduleName}
          rfrr="link-destination"
          href={url}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default DestinationCard;
