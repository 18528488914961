import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkText } from "@egds/react-core/text";

const RecommendationSection = (props: { recommendedPercent: number; className: string }) => {
  const { recommendedPercent, className } = props;
  const roundedPercentage = Math.round(recommendedPercent);

  if (!roundedPercentage) {
    return null;
  }

  const { formatText } = useLocalization();

  return (
    <UitkText size={200}>
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: formatText("destinationActivities.travellersRecommend", roundedPercentage),
        }}
      />
    </UitkText>
  );
};

export default RecommendationSection;
